<template>
  <component :is="optionComponent" />
</template>

<script>
import Park from "../models/Park.js";

export default {
  data() {
    return {
      validRoute: true,
      validMiddle: true,
      availableRoutes: null,
      availableMiddle: null,
    };
  },
  mounted() {
    var idRoute = this.$store.state.routeId;
    if (this.$route.name === "Home") {
      this.validRoute = true;
      this.$store.commit("setRouteId", "entrada");
    } else if (this.$route.name === "HomeId") {
      let park = this.$store.state.parkData;
      //let availableRoutes = park.routes.map((route) => route.id);

      var typeR = this.$store.state.typeRoute;
      console.log("tipo ruta", typeR);
      if(typeR == 2){
        console.log("larga");
        this.availableRoutes = park.routesLong.map((route) => route.id);
      }else{
        console.log("corta");
        this.availableRoutes = park.routes.map((route) => route.id);
      }

      console.log("available routes", this.availableRoutes);

      this.availableMiddle = park.middlePoints.map((route) => route.id);

      if (this.availableRoutes.includes(this.$route.params.id)) {
        this.$store.commit("setRouteId", this.$route.params.id);
        this.validRoute = true;
      } else {
        this.$store.commit("setRouteId", null);
        this.validRoute = false;
      }

      this.setupCurrentRoute(this.$store.state.routeId);

      if (this.availableMiddle.includes(this.$route.params.id)) {
        this.$store.commit("intermediate", this.$route.params.id);
        this.validMiddle = true;
        console.log(idRoute);
        if(idRoute !== null){
          this.setupCurrentRoute(idRoute);
          this.$store.commit("setRouteId", idRoute);
        }
      } else {
        this.$store.commit("intermediate", null);
        this.validMiddle = false;
      }
    }
  },
  computed: {
    optionComponent() {
      if (this.$route.name === "Home") {
        return () => import(`@/views/Home.vue`);
      } else if (this.$route.name === "HomeId") {
        if(this.validMiddle){
          return () => import(`@/views/Intermediate.vue`);
        }else{
          if (this.$store.state.onboardingState && this.validRoute) {
            return () => import(`@/views/Route.vue`);
          } else {
            return () => import(`@/views/Home.vue`);
          }
        }
      }
      return null;
    },
  },
  methods: {
    setupCurrentRoute(routeId) {
      let parkData = new Park(this.$store.state.parkData);
      let cRoute = parkData.getRouteWithId(routeId);
      this.$store.commit("currentRoute", cRoute);
      console.log("cRoute",cRoute);
    },
  },
};
</script>
